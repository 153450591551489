*
{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: 1.1px;
}

body{
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*border: solid 1px red;*/
}
.header
{
  width:100%;
  background: linear-gradient(to right, #ff7e5f, #feb47b, #86a8e7, #91eae4); /* Change the colors as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgbanner
{
  width:min(1000px,99%);
  height: 80px;
  align-items: center;  
}
.imgbanner h1
{
  width:100%;
  height:80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.imgbanner img
{
  width:100%;
  height:80px;
}
.font25
{
  font-size: 30px;  
  color: white;
  letter-spacing: 4px;
}
.App
{
  border:solid 1px green;
  display: flex;
  flex-direction: column;
  align-items: center;
  width:min(1400px,99vw);
  height:99vh;
}
.nav
{
  background-color: rgba(0,0,0,.7);
  width:100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:  "links-area";
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}
.navLink
{
  color:white;
  font-size: 13px;
  letter-spacing: 1.1px;   
  text-decoration: none;
}
.navLinkBlack
{
  color:rgba(30, 29, 29, 0.68);
  letter-spacing: 1.1px;   
  text-decoration: none;
}

.navuser
{
  background-color: rgba(0,0,0,.7);
  width:100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:  "links-areauser"
                        "search-areauser";
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-top: .4rem;
  padding-bottom: .4rem;
}
.navLinkuser
{
  color:white;
  font-size: 13px;
  letter-spacing: 1.1px;   
  text-decoration: none;
}
ul{
  list-style-type: none;
  margin: 0;
  padding:0;
  display: flex;
  justify-content: space-between;
}
.ul{
  list-style-type: none;
  margin: 0;
  padding:0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Link
{
  text-decoration: none;
}

main{
  width:100%;
  padding:.6rem;
  overflow-y:scroll;
  flex-grow: 1;
  /*border: solid 1px rgb(19, 18, 18);*/
}
.borderLess
{
  border:none;
  outline: none;
  border-bottom:solid 2px black;
  padding:.1rem;
  margin-top: .2rem;
  width: 99%;
}


.marginTop
{
  margin-top: 2.3rem;
}
.marginBottom
{
  margin-bottom: 3rem;
}
.marginTopSmall
{
  margin-top: .2rem;
}


.headertext
{ 
  color:rgb(106, 98, 98);
  font-size: 13px;
}
.footer
{
  padding: .1rem;
  margin: .1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 7px;
  font-weight: bold;
  letter-spacing: .1rem;
}
.footer p{
  color: blue;
}

.loginForm{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: .1rem;
  height: 100%;
  width: 100%;
}
.loginDiv
{
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  padding: .1rem;
  width:min(600px,100%);
  padding:.5rem;
  /*background-color: #c1e8eb;*/
  background: linear-gradient(to right, #e1a3ce,#e5d1df,#e3dae0,#e2e0e1); /* Change the colors as needed */
  border-radius: 10px;
  padding-top: 3rem;
}
.loginTypeDiv
{
  display:flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: .1rem;
  width:min(600px,100%);
  margin-bottom: .1rem;
}
.loginButtonyNav
{
  width:100%;
  display: flex;
  justify-content: flex-end;
  /*background-color: beige;*/
  padding: .3rem;
}
.actionpanel
{
  width:100%;
  display: flex;
  justify-content: space-between;
  /*background-color: beige;*/
  padding: .3rem;
}

.album
{ 
  width: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
  flex-wrap: wrap;
  gap: .2rem;
  margin-top: .5rem;
}
.imgThumbHome
{
  width:350px;
  height: 350px;
  position: relative;
  border:solid 1px rgb(105, 103, 103);
  border-radius: 1rem;
  overflow: hidden;
  margin-bottom: .5rem;
  align-items: center;
}
.imgThumbHome img {
  width: 100%;
  height: auto;
  max-width: min(350px, 100%);
  display: block; /* Ensures no extra space from inline elements */
}
.imgThumbHome img:hover
{
  filter:grayscale(100%);
  -webkit-filter: grayscale(100%);
  transition: filter 0.5 ease-in-out;
}

.imgThumbHome2 {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  max-width: min(350px, 100%);
  aspect-ratio: 1; /* Ensures a square container */
  border: solid 1px rgb(214, 211, 211);
}

.imgThumbHome2 img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensures the image fits completely without cropping */
}
.btnEditTxDetails {
  width: 200px; /* Width of the circle */
  height: 200px; /* Height equal to width for a perfect circle */
  border-radius: 50%; /* Circular shape */
  background: radial-gradient(circle, 
              rgba(255, 0, 0, 1) 0%,       /* Solid red at the center */
              rgba(255, 0, 0, 0.5) 70%,   /* Semi-transparent red */
              rgba(255, 0, 0, 0) 100%);   /* Fully transparent at the edge */
  border: none; /* Remove border */
  position: absolute; /* Positioning for centering if needed */
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; /* Text color */
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;z-index: 12; opacity: 0; /* Invisible by default */
  transition: opacity 0.3s ease; /* Smooth fade-in effect */
}

.imgThumbHome .imgInfo
{  
  position:absolute;
  top:0px;
  left:0px;
  background: rgba(0,0,0,0.6);
  opacity: 1;
  transition: 0.4s;
  width:100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  letter-spacing: .08rem;
  transition: all .3s;
}
.imgThumbHome:hover .btnEditTxDetails {
  opacity: 1; /* Fully visible on hover */
}
.imgThumbHome:hover .imgInfo
{
 opacity: 1;
 background: rgba(0,0,0,0.75);
 letter-spacing: .1rem;
}

.imgInfo .infoText{
font-size: 16px;
color:white;
}
.imgInfo .headerText{
font-size: 13px;
color:lightskyblue;
}
.imgThumb
{
  width:min(380px,100%);
  position: relative;
  border:solid 5px rgb(23, 9, 219);
  border-radius: 1rem;
  overflow: hidden;
  margin-bottom: .5rem;
  align-items: center;
}
.imgThumb img
{
  width:100%;
  min-height:100px;
  min-width: 100px;
}
.imgThumb img:hover
{
  filter:grayscale(100%);
  -webkit-filter: grayscale(100%);
  transition: filter 0.5 ease-in-out;
}
.imgThumb span
{
  display: none;
  position:absolute;
  top:20px;
  left:20px;
}
.imgThumb:hover span
{
  display: block;
}

.overLay
{
  color:white;
  background-color: black;
  z-index: 100;
}
.infoGroup
{
  display: grid; 
  width:100%;
  grid-template-rows: 1fr;
  grid-template-columns: .3fr .7fr;
  grid-template-areas: 'label info';
  padding-left:.4rem;
  padding-right:.4rem; 
}
.infoGroupSingle
{
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gridLabel
{
  grid-area: label;
  display: flex;
  justify-content: flex-end;
  padding-right: .2rem;
}
.gridInfo
{
  grid-area: info;
  display: flex;
  justify-content: flex-start;
  padding-left: .2rem;
}

.logOutDiv
{
  display: flex;
  justify-content: center;
  align-items: center;
}
.baseddDiv
{
  display: flex;
  justify-content: center;
  align-items:start;
  flex-wrap: wrap;
}
.columndiv
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:start;
}
.spinneroverlay
{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  top:0px;
  left:0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinnerbody{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
}
.spinnerbody p{
  color: white;
}
.yrostext
{
  text-align: center;
  padding: .3rem;
  font-size: .7rem;
  line-height: 1.3;
  margin-inline: auto;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1.2px;
  word-spacing: 2.4px;
  word-wrap: break-word;
  word-break: normal;
  color: white;
}
.uppercase
{
  text-transform:uppercase ;
}
.circlebutton{
  width: 4rem;
  height: 4rem;
  font-size: .9rem;
  border-radius:50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color:rgb(242, 244, 246);
  border:none;
}
.circlebutton:hover
{
  background-color: rgb(74, 74, 72);
}
.circularbuttonactive
{  
  background-color: rgb(231, 9, 243);
}
.circularbuttoninactive
{
  background-color: rgb(14, 145, 211);
}
.semcontainer
{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width:100%;
}
.fullWidth
{
  width: 100%;
}
.width1000
{
  width:min(1000px,100%);
}
.radiolabel
{
  display: flex;
}
.radiooptiondiv
{
  margin-left: .5rem;
}
.selectedloginoption
{
  font-weight: bold;
  color: blue;
}
.transparent
{
  background-color: transparent;
}
.loginButton{

font-size: .7rem;
}
.loginButtonsmall{
  font-size: .7rem;
  }

.centeredDiv
{
  display: flex;
  align-items: center;
  justify-content: center;
}
.btntext
{
  font-size: .8rem;
}
.parapasswordhint
{
  text-align: justify;
  font-size: .7rem;
}
.parapasswordhint span
{
  color: #2e07f1;
}
.featureButton{

  width:6rem;
  height:6rem;
  border-radius: 15px;
  background:linear-gradient(to right, #bce3e6,#dfe7e8); /* Change the colors as needed */
  font-size: .6rem;
  margin: .5rem;
  padding: .5rem;
  color: black;
}
.featureButton:hover
{
  background:linear-gradient(to right, #e6bcdb,#e4e1e4); /* Change the colors as needed */
}
.featureContainer
{
  display: flex;
  justify-content: center;
  align-items:start;
  flex-wrap: wrap;
}
.verticaldiv
{
  display: flex;
  flex-direction: column;
}
.hdiv
{
  display: flex;
}
.centrediv
{  
  justify-content: center;
  align-items:center;
}
.group
{
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.groupRow
{
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.grpSlnoContainer
{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;  
  padding: .5rem;font-weight: bold;
}
.grpTitleContainer
{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;  
  padding: .5rem;
  font-weight: bold;
}
.grpRuleContainer
{
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
  padding: .5rem;
}
.courseItemEdit
{
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border:solid 1px green;  
}
.OfferedCourseItem{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.OfferedCourseItemR1,.OfferedCourseItemR2,.OfferedCourseItemR3{
  display: flex;
  justify-content: center;
  align-items: center;
  width:99%;
}
.OfferedCourseItemR2,.OfferedCourseItemR3{
  margin-top: .9rem;
}
.OfferedCourseItemR1C1
{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width:50%;
}
.OfferedCourseItemR1C2
{
  display: flex;
  justify-content: center;
  align-items: center;
  width:50%;
}
.OfferedCourseItemCodeCover
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width:100%;
}
.OfferedCourseItemActionCover
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width:100%;
}
.OfferedCourseItemCodeLabel
{
  font-size: .6rem;
  color:gold;
}
.OfferedCourseItemCodeValue
{
  font-size: .8rem; 
}
.OfferedCourseItemTitleCover
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width:100%;
}
.OfferedCourseItemIPECrForType
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width:25%;
}
.OfferedCourseItemIPECrCover
{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:100%;
}
.OfferedCourseItemIPECr
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:17%;
}
.marginTopm
{
  margin-top: 1rem;
}
.offeredcourseactioncontainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.offeredcourseitemwithslno
{
  width:100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: .5rem;
  border-radius: 8px;
  /*background: linear-gradient(to right, #012d1a,#04492b,#09673e,#118653); /* Change the colors as needed */
  background: linear-gradient(to right, #014b72,#076192,#0f79b2,#1a95d8); /* Change the colors as needed */
  color:#fff;
}
.hcback {
  background: linear-gradient(to right, #275b93, #3678af, #4e8fbb, #70a3be); /* Dark to Light Blue */
}

.hgback {
  /*background: linear-gradient(to right, #356635, #5b8b5e, #8cc98e, #c1e1c1); /* Dark to Light Green */
  background: linear-gradient(to right, #1895d4, #239bd7, #54aedb, #69bce5); /* Dark to Medium-Light Sky Blue */
}

.heback {
  background: linear-gradient(to right, #e8538b, #f27aab, #f78fc5, #f8b3d1); /* Dark to Light Pink */
}

.aeback {
  background: linear-gradient(to right, #7a2f97, #9e56c4, #b684d3, #d0a2e1); /* Dark to Light Purple */
}

.seback {
  background: linear-gradient(to right, #585858, #7e7e7e, #a8a8a8, #d1d1d1); /* Dark to Light Gray */
}

.hcgrpback {
  background: #a6cade; /* Lighter Blue */
}

.hggrpback {
  background: #92d8fb; /* Lighter Green */
}

.hegrpback {
  background: #f9d1e5; /* Lighter Pink */
}

.aegrpback {
  background: #e2bbed; /* Lighter Purple */
}

.segrpback {
  background: #e0e0e0; /* Lighter Gray */
}
.selectedback {
  background: linear-gradient(to right, #184318, #326b36, #487d4a, #619461); 
}
.offeredcourseitemslnocontainer
{
  width:2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.offeredcourseitemslno
{
  width:1.9rem;
  height: 1.9rem;
  font-size: .8rem;
  border-radius: 50%;
  /*background-color: #10b0ef;*/
  border:none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.groupBack
{
  background-color: #d9eded;
}
.borderdiv
{
  border:solid 1px red;
}
.semestercourselistcontainer
{
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #109bdb;
}
.semesterheader
{
  width: min(1000px,100%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.semesterheadercol
{
  width: 32%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.semesterheadercolkey
{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: .9rem;
}
.semesterheadercolval
{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .9rem;
  font-weight: bold;
  padding: 5px;
}

.btn:hover {
  background-color: #137ab0;
}
.roundeddiv{
  border-radius: 10px;
}
.formfillupview{
  background-color: #d4e4ef;
}
.paddiv
{
  padding: .5rem;
}
.file-input-hidden {
  display: none; /* Hides the default file input */
}

.custom-file-label {
  display: inline-block;
  padding: 10px 20px;
  background-color: #04894d;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: .7rem;
}
.custom-file-label-primary {
  display: inline-block;
  padding: 10px 20px;
  background-color: #1da6af;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: .7rem;
}

.custom-file-label:hover {
  background-color: #0056b3;
}

.link-button {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px; /* Adjust to match typical link size */
  padding: 0;
  font-family: inherit; /* Match link font */
  height:20px;
}

.link-button:hover {
  color: darkblue;
}

.link-button:active {
  color: red;
}
.actionBar
{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background-color: #d8dbdd;
  padding: 5px;
}
.paymentdetailsdiv
{
  background-color: #dee4e8;
}
.roundedCorner
{
  border-radius: 5px;
}
.actiondiv
{
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.hdivwrapcontainer {
  display: flex;
  flex-wrap: wrap;       /* Enables wrapping */
  gap: 10px;             /* Adds space between items */
  justify-content: center; /* Centers items horizontally */
  padding: 10px;         /* Optional padding */
  background-color: #f0f0f0; /* Background color for visualization */
}
.hdivwrapcontainer-item
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 130px;
  padding: 10px;
  border-radius: 5px;
  background-color: #109bdb;
  font-size: .7rem;
}
.hdivwrapcontainer-item p
{
 width: 100%;
 text-align: center;
}
.hdivwrapcontainer-item  .infotext
{
 color: white;
 font-weight: bold;
 font-size: 1.2rem;
}
.inputGroup
{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.inputGroup>label
{
  font-size: .7rem;
}
.inputGroup>select
{
  font-size: .9rem;
}
.errorPara{
  font-size: .6rem;
}